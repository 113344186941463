import { Pagination, TransactionDto } from "interfaces/interfaces";
import { useEffect } from "react";
import useSWR from "swr";
import _ from "lodash";
import { useSearchParams } from "react-router-dom";

const useBets = () => {
  const [searchParams] = useSearchParams();

  const fetcher = async (url: string) => {
    const queryParams = new URLSearchParams({
      Token: searchParams.get("token") as string,
    });
    const response = await fetch(`${url}?${queryParams}`, {
      method: "GET",
    });

    return await response.json();
  };

  const { data, isValidating, mutate } = useSWR<Pagination<TransactionDto>>(`${process.env.REACT_APP_API_URL}/game/player-transactions`, fetcher, { refreshInterval: 10000 });

  useEffect(() => {
    mutate();
  }, []);

  return { data, isValidating };
};

export default useBets;

import { Button, Grid, TextField } from "@mui/material";
import { Data } from "interfaces/interfaces";
import moment from "moment";
import { useEffect, useState } from "react";
import signalRService from "services/signalRService";

const SignalRComponent = () => {
  const [messages, setMessages] = useState<Data[]>([]);
  const [user, setUser] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    signalRService.onReceiveMessage((receivedJson: string) => {
      console.log("Message receive", receivedJson);

      let object: Data = JSON.parse(receivedJson);

      object.deliver = moment().format("YYYY-MM-DDTHH:mm:ss.SSSZZ");

      object.time = Date.now() - object.time;

      setMessages([...messages, object]);

      console.log("Messages array", messages);
    });

    // return () => {
    //   signalRService.connection.stop();
    //   console.log("SignalR connection stoped.", moment().format("YYYY-MM-DDTHH:mm:ss.SSSZZ"));
    // };
  }, [messages]);

  const sendMessage = () => {
    let payload = JSON.stringify({ user: user, text: message, timestamp: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZZ"), tag: "Client", time: Date.now() });

    console.log("Send message: ", payload);

    signalRService.sendMessage(payload);

    setMessage("");
  };

  const removeMessages = () => {
    setMessages([]);
  };

  return (
    <div className="SignalRComponent">
      <h1>React SignalR Websocket</h1>
      <Grid container spacing={2}>
        <Grid item xs={5}></Grid>
        <Grid item xs={1}>
          <TextField onChange={(e) => setUser(e.target.value)} id="outlined-disabled" label="Name" />
        </Grid>
        <Grid item xs={1}>
          <TextField onChange={(e) => setMessage(e.target.value)} id="outlined-disabled" label="Message" />
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={1}>
          <Button onClick={sendMessage} variant="outlined">
            Send
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button onClick={removeMessages} variant="contained">
            Delete messages
          </Button>
        </Grid>
        <Grid item xs={5}></Grid>
      </Grid>
      <h2>Received messages</h2>
      <div>
        {messages.map((msg, index) => (
          <div key={index}>
            <h3>Message {index}</h3>
            <strong>User : {msg.user}</strong> <br />
            <strong>Text : {msg.text}</strong> <br />
            <strong>Tag : {msg.tag}</strong> <br />
            <strong>Timestamp : {msg.timestamp}</strong> <br />
            <strong>Deliver : {msg.deliver}</strong> <br />
            <strong>Time : {msg.time} ms</strong> <br />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SignalRComponent;

import { Box, Card, CardHeader, Grid } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import _ from "lodash";
import { useAppContext } from "hooks/useAppContext";

const LogsComponent = () => {
  const context = useAppContext();

  return (
    <Card sx={{ m: 5 }}>
      <CardHeader title={"LOGS"} />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 800 }}>
          {context.messages.map((msg, index) => (
            <div key={index}>
              <strong>Message : {msg}</strong> <br />
            </div>
          ))}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default LogsComponent;

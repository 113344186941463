import { Button, CircularProgress, Divider, Grid, TextField, Typography } from "@mui/material";
import useSession from "hooks/useSession";
import _ from "lodash";

const SessionComponent = () => {
  const { formik, error, isValidating, url } = useSession();

  return (
    <div>
      {_.isEmpty(url) ? (
        <Grid container xs={12} gap={2}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Grid container xs={12} gap={2} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <form onSubmit={formik.handleSubmit}>
                {isValidating ? (
                  <CircularProgress />
                ) : (
                  <Grid xs={12} gap={2}>
                    <Grid xs={12} gap={2}>
                      <TextField
                        type="text"
                        value={formik.values.player}
                        onChange={formik.handleChange}
                        error={formik.touched.player && Boolean(formik.errors.player)}
                        helperText={formik.touched.player && formik.errors.player}
                        id="player"
                        label="Fill player name"
                        name="player"
                      />
                    </Grid>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Grid xs={12} gap={2}>
                      <TextField
                        type="number"
                        value={formik.values.balance}
                        onChange={formik.handleChange}
                        error={formik.touched.balance && Boolean(formik.errors.balance)}
                        helperText={formik.touched.balance && formik.errors.balance}
                        id="balance"
                        label="Balance"
                        name="balance"
                      />
                    </Grid>
                  </Grid>
                )}
                {error && (
                  <Typography component="h6" variant="h6" sx={{ mt: 4, color: "red" }}>
                    {error.message}
                  </Typography>
                )}
                <Divider sx={{ m: 4 }} />
                <Button sx={{ p: 2 }} color={"secondary"} type="submit" variant={"outlined"}>
                  Start game
                </Button>
              </form>
            </Grid>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      ) : (
        `YOUR GAME IFRAME URL = ${url}`
      )}
    </div>
  );
};

export default SessionComponent;

import { Box, Card, CardHeader, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import _ from "lodash";
import useSessions from "hooks/useSessions";

const SessionsComponent = () => {
  const { data, isValidating } = useSessions({ id: "" });
  return (
    <Card sx={{ m: 5 }}>
      <CardHeader title={"SESSIONS"} />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 800 }}>
          {isValidating || data == undefined ? (
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress size="10rem" sx={{ m: 3 }} />
            </Box>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Player</TableCell>
                  <TableCell>Token</TableCell>
                  <TableCell>Balance</TableCell>
                  <TableCell>Currency</TableCell>
                  <TableCell>GameCode</TableCell>
                  <TableCell>IP</TableCell>
                  <TableCell>LobbyUrl</TableCell>
                  <TableCell>Platform</TableCell>
                  <TableCell>Language</TableCell>
                  <TableCell>Auth</TableCell>
                  <TableCell sortDirection="desc">
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel active direction="desc">
                        Created
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.isEmpty(data) == false &&
                  data?.items.map((session) => (
                    <TableRow hover key={session.id}>
                      <TableCell>{session.playerId}</TableCell>
                      <TableCell>{session.token}</TableCell>
                      <TableCell>{session.balance}</TableCell>
                      <TableCell>{session.currency}</TableCell>
                      <TableCell>{session.gameCode}</TableCell>
                      <TableCell>{session.ip}</TableCell>
                      <TableCell>{session.lobbyUrl}</TableCell>
                      <TableCell>{session.platform}</TableCell>
                      <TableCell>{session.language}</TableCell>
                      <TableCell>{session.auth}</TableCell>
                      <TableCell>{session.created.toString()}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default SessionsComponent;

import { Box, Card, CardHeader, CircularProgress, Divider, Typography } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import _ from "lodash";
import useDashboard from "hooks/useDashboard";

const DashboardComponent = () => {
  const { data, isValidating } = useDashboard();
  return (
    <Card sx={{ m: 5 }}>
      <CardHeader title={"DATA"} />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 800 }}>
          {isValidating ? (
            <Box style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
              <CircularProgress size="10rem" sx={{ m: 3 }} />
            </Box>
          ) : (
            <Box style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
              <Typography component="h6" variant="h6">
                Number of transactions - {data?.count}
              </Typography>
              <Typography component="h6" variant="h6">
                Sum of bets - {data?.sumBets}$
              </Typography>
              <Typography component="h6" variant="h6">
                Sum of wins - {data?.sumWins}$
              </Typography>
              <Typography component="h6" variant="h6">
                Sum of GGR - {data?.sumGGR}$
              </Typography>
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default DashboardComponent;

import { Box, Card, CardHeader, CircularProgress, Typography } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import _ from "lodash";
import useLeaderBoard from "hooks/useLeaderBoard";

const LeaderboardComponent = () => {
  const { data, isValidating } = useLeaderBoard();
  return (
    <Card sx={{ m: 1 }}>
      <CardHeader title={"LEADERBOARD"} />
      <PerfectScrollbar>
        {isValidating || data == undefined ? (
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size="10rem" sx={{ m: 1 }} />
          </Box>
        ) : (
          data?.map((msg, index) => (
            <div key={index}>
              <Typography sx={{ m: 1, color: "blue" }}>
                {msg.player} - {msg.multiplier}x - {msg.win}$
              </Typography>
            </div>
          ))
        )}
      </PerfectScrollbar>
    </Card>
  );
};

export default LeaderboardComponent;

import { Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";
import GameRoundsComponent from "../../components/data-tables/GameRoundsComponent";
import OperatorComponent from "../../components/data-tables/OperatorsComponent";
import PlayersComponent from "../../components/data-tables/PlayersComponent";
import SessionsComponent from "../../components/data-tables/SessionsComponent";
import SignalRComponent from "../../components/SignalRComponent";
import TransactionsComponent from "../../components/data-tables/TransactionsComponent";
import LogsComponent from "../../components/LogsComponent";
import DashboardComponent from "../../components/data-tables/DashboardComponent";
import SessionComponent from "../../components/SessionComponent";

const SwitchComponent = () => {
  const [selectedValue, setSelectedValue] = useState("game");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div>
      <Divider orientation="vertical" variant="middle" flexItem />
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">Select functionality</FormLabel>
        <Divider orientation="vertical" variant="middle" flexItem />
        <RadioGroup row onChange={handleChange} aria-labelledby="demo-radio-buttons-group-label" defaultValue={selectedValue} name="row-radio-buttons-group">
          <FormControlLabel value="session" control={<Radio />} label="Create session" />
          <FormControlLabel value="players" control={<Radio />} label="BO Players" />
          <FormControlLabel value="operators" control={<Radio />} label="BO Operators" />
          <FormControlLabel value="sessions" control={<Radio />} label="BO Sessions" />
          <FormControlLabel value="gameRounds" control={<Radio />} label="BO GameRounds" />
          <FormControlLabel value="transactions" control={<Radio />} label="BO Transactions" />
          <FormControlLabel value="dashboard" control={<Radio />} label="BO Dashboard" />
          <FormControlLabel value="logs" control={<Radio />} label="BO Logs" />
          <FormControlLabel value="connection" control={<Radio />} label="Test connection" />
        </RadioGroup>
      </FormControl>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Divider orientation="horizontal" flexItem />
      <Divider orientation="vertical" variant="middle" flexItem />
      {selectedValue === "session" && <SessionComponent />}
      <br />
      {selectedValue === "players" && <PlayersComponent />}
      <br />
      {selectedValue === "operators" && <OperatorComponent />}
      <br />
      {selectedValue === "sessions" && <SessionsComponent />}
      <br />
      {selectedValue === "gameRounds" && <GameRoundsComponent />}
      <br />
      {selectedValue === "transactions" && <TransactionsComponent />}
      <br />
      {selectedValue === "dashboard" && <DashboardComponent />}
      <br />
      {selectedValue === "logs" && <LogsComponent />}
      <br />
      {selectedValue === "connection" && <SignalRComponent />}
    </div>
  );
};

export default SwitchComponent;

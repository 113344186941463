import { Box, Card, CardHeader, CircularProgress, Typography } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import _ from "lodash";
import useHistory from "hooks/useHistory";

const HistoryComponent = () => {
  const { context, isValidating } = useHistory();
  return (
    <Card sx={{ m: 1 }}>
      <CardHeader title={"HISTORY"} />
      <PerfectScrollbar>
        {_.isEmpty(context.history) || isValidating ? (
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size="10rem" sx={{ m: 1 }} />
          </Box>
        ) : (
          context.history.map((msg, index) => (
            <div key={index}>
              <Typography sx={{ m: 1 }}>GameRoundId : {msg.id}</Typography>
              <Typography sx={{ m: 1 }}>Multiplier : {msg.multiplier}</Typography>
              <Typography sx={{ m: 1 }}>Time : {msg.created.toString()}</Typography>
            </div>
          ))
        )}
      </PerfectScrollbar>
    </Card>
  );
};

export default HistoryComponent;

import { BaseMessage, Bet, CashOut, GameRoundDto } from "interfaces/interfaces";
import { useEffect, useState } from "react";
import signalRService from "services/signalRService";
import { useAppContext } from "hooks/useAppContext";
import useCountdown from "hooks/useCountdown";
import { isFalsy } from "utility-types";
import { useSearchParams } from "react-router-dom";
import usePlayerDetail from "./usePlayerDetail";

const useGame = () => {
  const context = useAppContext();
  const [searchParams] = useSearchParams();

  usePlayerDetail();

  const [selectedValue, setSelectedValue] = useState("game");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const [messages, setMessages] = useState<string[]>([]);
  const [multiplier, setMultiplier] = useState(0);
  const [gameRound, setGameRound] = useState<GameRoundDto>({} as GameRoundDto);
  const [stake, setStake] = useState<number>(0);
  const [bet, setBet] = useState<boolean>(false);
  const [buttonBetDisabled, setButtonBetDisabled] = useState<boolean>(true);
  const [buttonCashoutDisabled, setButtonCashoutDisabled] = useState<boolean>(true);
  const [stakeDisabled, setStakeDisabled] = useState<boolean>(true);
  const [win, setWin] = useState<number>(0);
  const [start, setStart] = useState<boolean>(false);
  const [betReceived, setBetReceived] = useState<boolean>(false);
  const [cashoutReceived, setCashoutReceived] = useState<boolean>(false);

  let { seconds, setCountdown } = useCountdown();

  const formatPayload = (receivedJson: string) => {
    let object: BaseMessage = JSON.parse(receivedJson);

    let payload = JSON.stringify(object);

    context.setMessages([...context.messages, payload]);
  };

  useEffect(() => {
    let param = searchParams.get("token");
    context.setToken(param as string);
  }, []);

  useEffect(() => {
    signalRService.onReceiveBet((receivedJson: string) => {
      formatPayload(receivedJson);

      setMessages([...messages, receivedJson]);
      setBetReceived(true);
    });
  });

  useEffect(() => {
    signalRService.onReceiveCashout((receivedJson: string) => {
      formatPayload(receivedJson);

      setMessages([...messages, receivedJson]);
      setCashoutReceived(true);
    });
  });

  useEffect(() => {
    signalRService.onReceiveStart((receivedJson: string) => {
      formatPayload(receivedJson);

      setCountdown(0);
      setMessages([...messages, receivedJson]);
      setButtonBetDisabled(true);
      setButtonCashoutDisabled(isFalsy(bet));
      setStakeDisabled(true);
      setStart(true);
    });
  });

  useEffect(() => {
    signalRService.onReceiveEnd((receivedJson: string) => {
      formatPayload(receivedJson);
      setButtonBetDisabled(false);
      setButtonCashoutDisabled(true);
      setStakeDisabled(false);
      setStake(0);
      setCashoutReceived(false);
      setWin(0);
      setStart(false);
      setBetReceived(false);
      setCountdown(Date.now() + 30000);
      setMessages([]);

      let object: GameRoundDto = JSON.parse(receivedJson);
      context.setHistory([...context.history, object]);
    });
  });

  useEffect(() => {
    signalRService.onReceiveGameRound((receivedJson: string) => {
      formatPayload(receivedJson);

      let object: GameRoundDto = JSON.parse(receivedJson);

      setMultiplier(object.multiplier);
      setMessages([...messages, receivedJson]);

      if (gameRound.id !== object.id) {
        setGameRound(object);
      }
    });
  }, [messages]);

  const clickButtonBet = () => {
    setBet(true);
    setButtonBetDisabled(true);
    setStakeDisabled(true);

    let bet: Bet = { amount: stake, token: context.token, roundTime: [Date.now()], type: "CLIENT-SERVER-CLIENT", name: "BET" };

    let payload = JSON.stringify(bet);

    signalRService.sendBet(payload);

    context.setBalance(context.balance - stake);
  };

  const clickButtonCashout = () => {
    let win = multiplier * stake;

    let cashout: CashOut = { multiplier: multiplier, amount: win, token: context.token, roundTime: [Date.now()], type: "CLIENT-SERVER-CLIENT", name: "CASHOUT" };

    let payload = JSON.stringify(cashout);

    signalRService.sendCashout(payload);

    setWin(win);
    context.setBalance(context.balance + win);

    setButtonCashoutDisabled(true);
  };

  const changeStake = (data: string) => {
    let stake = Number(data);

    setStake(stake);

    if (stake > 0) {
      setButtonBetDisabled(false);
    } else {
      setButtonBetDisabled(true);
    }

    if (stake > context.balance) {
      setButtonBetDisabled(true);
    } else {
      setButtonBetDisabled(false);
    }
  };

  return {
    seconds,
    cashoutReceived,
    betReceived,
    start,
    win,
    bet,
    stake,
    messages,
    multiplier,
    buttonBetDisabled,
    buttonCashoutDisabled,
    stakeDisabled,
    clickButtonBet,
    clickButtonCashout,
    changeStake,
    context,
    selectedValue,
    handleChange,
  };
};

export default useGame;

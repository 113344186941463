import { useState, useEffect } from "react";

const useCountdown = () => {
  const [seconds, setSeconds] = useState<number>(30);
  const [countdown, setCountdown] = useState<number>(Date.now() + 30000);

  useEffect(() => {
    let updateTime = setInterval(() => {
      var now = Date.now();

      var difference = countdown - now;

      if (difference <= 0) {
        clearInterval(updateTime);
      } else {
        setSeconds(Math.floor((difference % (1000 * 60)) / 1000));
      }
    });
    return () => {
      clearInterval(updateTime);
    };
  }, [countdown]);

  return { seconds, setCountdown };
};

export default useCountdown;

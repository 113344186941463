import { LeaderBoardDto } from "interfaces/interfaces";
import { useEffect } from "react";
import useSWR from "swr";
import { useAppContext } from "./useAppContext";

const useLeaderBoard = () => {
  const context = useAppContext();

  const fetcher = async (url: string) => {
    const queryParams = new URLSearchParams({
      Token: context.token,
    });
    const response = await fetch(`${url}?${queryParams}`, {
      method: "GET",
    });

    return await response.json();
  };

  const { data, isValidating, mutate } = useSWR<LeaderBoardDto[]>(`${process.env.REACT_APP_API_URL}/game/leaderboard`, fetcher, { refreshInterval: 10000 });

  useEffect(() => {
    mutate();
  }, []);

  return { data, isValidating };
};

export default useLeaderBoard;

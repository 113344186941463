import { Box, Card, CardHeader, CircularProgress, Typography } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import _ from "lodash";
import useBets from "hooks/useBets";

const BetsComponent = () => {
  const { data, isValidating } = useBets();
  return (
    <Card sx={{ m: 1 }}>
      <CardHeader title={"MY BETS"} />
      <PerfectScrollbar>
        {_.isEmpty(data) || isValidating ? (
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size="10rem" sx={{ m: 1 }} />
          </Box>
        ) : (
          data?.items.map((msg, index) => (
            <div key={index}>
              <Typography sx={{ m: 1 }}>
                BET : {msg.euros} , WIN : {msg.winEuros}$ , MULTIPLIER {msg.multiplier}x
              </Typography>
            </div>
          ))
        )}
      </PerfectScrollbar>
    </Card>
  );
};

export default BetsComponent;

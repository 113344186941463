import { Button, Card, CardHeader, TextField, Typography } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import _ from "lodash";
import useChat from "hooks/useChat";

const ChatComponent = () => {
  const { message, messages, setMessage, clickButtonChat } = useChat();
  return (
    <Card sx={{ m: 1 }}>
      <CardHeader title={"CHAT"} />
      <PerfectScrollbar>
        <TextField sx={{ m: 2 }} type="text" onChange={(e) => setMessage(e.target.value)} id="outlined-disabled" label="Message" defaultValue="Your message" value={message} />
        <Button sx={{ m: 2 }} disabled={_.isEmpty(message)} onClick={clickButtonChat} variant="contained">
          SEND MESSAGE
        </Button>
        <br />
        {messages.sort().map((msg, index) => (
          <div key={index}>
            <Typography component="h6" variant="h6" sx={{ m: 2, color: "blue" }}>
              {msg.player} : {msg.created}
            </Typography>
            <Typography component="h6" variant="h6" sx={{ m: 2, color: "black" }}>
              {msg.message}
            </Typography>
          </div>
        ))}
      </PerfectScrollbar>
    </Card>
  );
};

export default ChatComponent;

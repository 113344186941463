import { Box, Card, CardHeader, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import _ from "lodash";
import useGameRounds from "hooks/useGameRounds";

const GameRoundsComponent = () => {
  const { data, isValidating } = useGameRounds({ id: "" });
  return (
    <Card sx={{ m: 5 }}>
      <CardHeader title={"GAME ROUNDS"} />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 800 }}>
          {isValidating || data == undefined ? (
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress size="10rem" sx={{ m: 3 }} />
            </Box>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Active</TableCell>
                  <TableCell>Start</TableCell>
                  <TableCell>End</TableCell>
                  <TableCell>Multiplier</TableCell>
                  <TableCell sortDirection="desc">
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel active direction="desc">
                        Created
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.isEmpty(data) == false &&
                  data?.items.map((gameRound) => (
                    <TableRow hover key={gameRound.id}>
                      <TableCell>{gameRound.active.toString()}</TableCell>
                      <TableCell>{gameRound.start.toString()}</TableCell>
                      <TableCell>{gameRound.end.toString()}</TableCell>
                      <TableCell>{gameRound.multiplier.toString()}</TableCell>
                      <TableCell>{gameRound.created.toString()}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default GameRoundsComponent;

import { swrSingleGetConfig } from "configs/swrConfig";
import { GameRoundDto, Pagination } from "interfaces/interfaces";
import { useEffect } from "react";
import useSWR from "swr";

type Props = {
  id: string;
};

const useGameRounds = ({ id }: Props) => {
  useEffect(() => {
    mutate();
  }, []);

  const fetcher = async (url: string) => {
    const queryParams = new URLSearchParams({
      Id: id,
    });
    const response = await fetch(`${url}?${queryParams}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${""}`,
      },
    });

    return await response.json();
  };
  const { data, isValidating, mutate } = useSWR<Pagination<GameRoundDto>>(`${process.env.REACT_APP_API_URL}/operator/game-rounds`, fetcher, swrSingleGetConfig);

  return { data, isValidating, mutate };
};
export default useGameRounds;

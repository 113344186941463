import "./App.css";
import SwitchComponent from "pages/switch/SwitchComponent";
import { AppContextProvider } from "hooks/useAppContext";
import { useState } from "react";
import GameComponent from "pages/game/GameComponent";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GameRoundDto } from "interfaces/interfaces";

function App() {
  const [start, setStart] = useState(false);
  const [balance, setBalance] = useState(0);
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [messages, setMessages] = useState<string[]>([]);
  const [history, setHistory] = useState<GameRoundDto[]>([]);

  return (
    <div className="App">
      <AppContextProvider context={{ start, setStart, balance, setBalance, name, setName, token, setToken, messages, setMessages, history, setHistory }}>
        <BrowserRouter>
          <Routes>
            <Route index element={<SwitchComponent />} />
            <Route path="game" element={<GameComponent />} />
          </Routes>
        </BrowserRouter>
      </AppContextProvider>
    </div>
  );
}

export default App;

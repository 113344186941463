import { swrSingleGetConfig } from "configs/swrConfig";
import { Chat, Pagination } from "interfaces/interfaces";
import moment from "moment";
import { useEffect, useState } from "react";
import signalRService from "services/signalRService";
import useSWR from "swr";
import { useAppContext } from "./useAppContext";

const useChat = () => {
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<Chat[]>([]);
  const context = useAppContext();

  const clickButtonChat = () => {
    let chat: Chat = { player: context.name, message: message, created: moment().format("MMMM Do YYYY, h:mm:ss a"), roundTime: [Date.now()], type: "CLIENT-SERVER-CLIENT", name: "CHAT" };

    let payload = JSON.stringify(chat);

    signalRService.sendChat(payload);

    setMessage("");
  };

  const fetcher = async (url: string) => {
    const response = await fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${""}`,
      },
    });

    return await response.json();
  };

  const { data, mutate } = useSWR<Pagination<Chat>>(`${process.env.REACT_APP_API_URL}/game/chat`, fetcher, swrSingleGetConfig);

  useEffect(() => {
    if (data?.items) {
      setMessages(data?.items);
    }
  }, [data]);

  useEffect(() => {
    mutate();
  }, []);

  useEffect(() => {
    signalRService.onReceiveChat((receivedJson: string) => {
      let object: Chat = JSON.parse(receivedJson);
      setMessages([...messages, object]);
    });
  });

  return { message, messages, setMessage, clickButtonChat };
};
export default useChat;

import { PlayerDetailDto } from "interfaces/interfaces";
import { useEffect } from "react";
import useSWR from "swr";
import _ from "lodash";
import { useSearchParams } from "react-router-dom";
import { swrSingleGetConfig } from "configs/swrConfig";
import { useAppContext } from "./useAppContext";

const usePlayerDetail = () => {
  const context = useAppContext();
  const [searchParams] = useSearchParams();

  const fetcher = async (url: string) => {
    const queryParams = new URLSearchParams({
      Token: searchParams.get("token") as string,
    });
    const response = await fetch(`${url}?${queryParams}`, {
      method: "GET",
    });

    return await response.json();
  };

  const { data, mutate } = useSWR<PlayerDetailDto>(`${process.env.REACT_APP_API_URL}/operator/player-detail`, fetcher, swrSingleGetConfig);

  useEffect(() => {
    mutate();
  }, []);

  useEffect(() => {
    context.setBalance(data?.balance as number);
    context.setName(data?.userName as string);
  }, [data]);
};

export default usePlayerDetail;

import { Box, Button, Card, CardHeader, CircularProgress, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import HistoryComponent from "components/HistoryComponent";
import LogsComponent from "components/LogsComponent";
import useGame from "hooks/useGame";
import _ from "lodash";
import BetsComponent from "../../components/BetsComponent";
import ChatComponent from "../../components/ChatComponent";
import LeaderboardComponent from "../../components/LeaderboardComponent";

const GameComponent = () => {
  const { seconds, cashoutReceived, betReceived, start, win, stake, multiplier, buttonBetDisabled, buttonCashoutDisabled, stakeDisabled, clickButtonBet, clickButtonCashout, changeStake, context, selectedValue, handleChange } = useGame();

  return (
    <div>
      <Divider orientation="vertical" variant="middle" flexItem />
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">Select functionality</FormLabel>
        <Divider orientation="vertical" variant="middle" flexItem />
        <RadioGroup row onChange={handleChange} aria-labelledby="demo-radio-buttons-group-label" defaultValue={selectedValue} name="row-radio-buttons-group">
          <FormControlLabel value="game" control={<Radio />} label="Game" />
          <FormControlLabel value="logs" control={<Radio />} label="Logs" />
        </RadioGroup>
      </FormControl>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Divider orientation="horizontal" flexItem />
      <Divider orientation="vertical" variant="middle" flexItem />
      <br />
      {selectedValue === "logs" && <LogsComponent />}
      <br />
      {selectedValue === "game" && (
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <HistoryComponent />
          </Grid>
          <Grid item xs={2}>
            <ChatComponent />
          </Grid>
          <Grid item xs={4}>
            <Card sx={{ m: 1 }}>
              <CardHeader title={"GAME"} />
              <Grid item xs={12} gap={2}>
                {_.isEmpty(context.name) ? (
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress size="10rem" sx={{ m: 1 }} />
                  </Box>
                ) : (
                  <Box>
                    <h2>Player - {context.name}</h2>
                    <h3>Balance - {context.balance}</h3>
                  </Box>
                )}
                {betReceived && <h3 style={{ color: "blue" }}>Bet received - {stake}</h3>}
                {start === false && (
                  <>
                    <h2> Last multiplier - {multiplier}x</h2>
                    <h1 style={{ color: "blue" }}>Waiting for new round</h1>
                    <h1>{seconds}</h1>
                    <CircularProgress />
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Divider orientation="horizontal" flexItem />
                  </>
                )}
                {start && (
                  <>
                    <h1> {multiplier}x</h1>
                  </>
                )}
                {cashoutReceived && <h1 style={{ color: "green" }}>WIN {win}</h1>}
                <Divider orientation="vertical" variant="middle" flexItem />
              </Grid>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Grid container xs={12} gap={2}>
                <Grid xs={12}>
                  <TextField disabled={stakeDisabled} type="number" onChange={(e) => changeStake(e.target.value)} id="outlined-disabled" label="Stake" defaultValue="Your Stake" value={stake === 0 ? "" : stake} />
                </Grid>
                <Grid xs={12}>
                  <Button disabled={buttonBetDisabled} onClick={clickButtonBet} variant="contained">
                    BET
                  </Button>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Button disabled={buttonCashoutDisabled} onClick={clickButtonCashout} variant="contained">
                    CASHOUT
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={2}>
            <BetsComponent />
          </Grid>
          <Grid item xs={2}>
            <LeaderboardComponent />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default GameComponent;
